<template>
  <div class="col-md-12" style="margin-top: 3%; margin-bottom: 3%">
    <div class="card card-container">
      <div class="card-header" style="background-color: #212529; color: white">
        <h5>Glemt passordet?</h5>
      </div>

      <div class="profile-img-card" id="profile-img"></div>

      <div id="alert" v-if="alert">{{ alert }}</div>

      <form
        @submit.prevent="requestPasswordReset"
        style="background-color: #212529; color: white"
      >
        <div class="form-group">
          <label for="email"> E-post </label>
          <Field
            name="email"
            type="email"
            class="form-control"
            v-model="email"
          />
          <ErrorMessage name="email" class="error-feedback" />
        </div>
        <div class="form-group" style="margin-top: 16px">
          <b-button
            type="submit"
            class="btn btn-primary btn-block w-100"
            variant="success"
            :disabled="loading"
            :pressed="false"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Send forespørsel om å nullstille passordet</span>
          </b-button>
        </div>
      </form>
      <br />
      <div class="loginlink" style="margin-top: 16px">
        <router-link v-if="showLoginLink" to="/innlogging" class="nav-link">
          Gå til innloggingssiden
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import UserDataService from "../../services/user.service";
import { Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "GlemtPassord",
  components: {
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("E-post er påkrevet ")
        .email("E-posten er i ugyldig format ")
        .max(250, "E-posten må være under 250 tegn! "),
    });
    return {
      email: "",
      alert: "",
      showLoginLink: false,
      schema,
    };
  },
  methods: {
    requestPasswordReset() {
      this.alert = "";
      // Verify that the passwords match
      if (this.password !== this.passwordVerify) {
        this.alert = "Passordene stemmer ikke med hverandre";
        return;
      }
      // call the service
      console.log(this.$route.query.passwordResetId);

      UserDataService.requestPasswordReset(this.email)
        .then((response) => {
          console.log(response.data);
          this.alert =
            "E-post blir sendt hvis e-posten eksiterer. Husk å sjekke spamfolderen hvis du ikke finner noe e-post.";
          this.showLoginLink = true;
        })
        .catch((error) => {
          console.log(error.response);
          this.alert = error.response.data.message;
        });
    },
  },
};
</script>
   
 

<style scoped>
button,
input {
  display: block;
  margin-bottom: 10px;
}

#alert {
  color: red;
  margin-bottom: 10px;
}

/* Form container */
.card-container.card {
  max-width: 500px !important;
  padding: 30px 30px;
}

/* Form styling */
.card {
  background-color: #212529;
  padding: 16px;
  margin: 0 auto;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

/* Input focus white glow */
.form-control:focus {
  border-color: white;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px white;
}

/* Logo */
.profile-img-card {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: -30px;
  margin-bottom: -100px;
}

/* Animated logo */
.animlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

/* Error message */
.error-feedback {
  color: red;
}

/* Login link styling */
.loginlink a {
  color: white;
}

.loginlink a:active {
  color: #9b9d9e;
}
</style>